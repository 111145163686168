import StoreForms from './store-forms';
import StoreGeneral from './store-general';
import StoreUi from './store-ui';
import StoreIds from './store-ids';
import StoreNavigation from './store-navigation';
import StoreContent from './store-content';
// import StorePayments from './store-payments';
// import StoreSleepout from './store-sleepout';
// import StoreOrders from './store-orders';

class StoreRoot {

    constructor() {  
        this.storeForms = new StoreForms(this);
        this.storeGeneral = new StoreGeneral(this);
        this.storeIds = new StoreIds(this);
        this.storeUi = new StoreUi(this);
        this.storeNavigation = new StoreNavigation(this);
        this.storeContent = new StoreContent(this);
        // this.storePayments = new StorePayments(this);
        // this.storeSleepout = new StoreSleepout(this);
        // this.storeOrders = new StoreOrders(this);
    }
    
}

export default StoreRoot;