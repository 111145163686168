import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

import FormContact from '../forms/form-contact';

const BlockContact = inject("stores") (
    observer (
        class BlockContact extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    items: []
                };
            }

            componentDidMount = async() => {
                let idx;
                // idx = 63; //NL DEV
                // idx = 172; //EN DEV
                // idx = 33 //NL
                idx = 78; //EN
                const block1 = await this.storeContent.getContentBlock('pageblocks', idx);

                this.setState({
                    block1: block1
                })
            }

            componentDidUpdate = async(prevProps) => {

            }

            handleNav = (item) => {

            }

            handleNavRoute = (page) => {
                const idx = 39;
                this.storeNavigation.setCurrentPage(idx);
                //const url = '/content/' + page.toLowerCase();
                const url = '/' + page.toLowerCase();
                this.props.navigate(url);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="section section--contact">
                            <div className="content-columns">
                                <div 
                                    className="content-column  content-column--main --link"
                                    onClick = {() => this.handleNavRoute('contact')}
                                >
                                    {this.state.block1 && this.state.block1.content &&
                                        <h2 
                                            className="content__header" 
                                            dangerouslySetInnerHTML={{ __html: this.state.block1.title.rendered}}
                                        />
                                    }
                                    {/* {this.state.block1 && this.state.block1.content &&
                                        <div 
                                            className="content__text" 
                                            dangerouslySetInnerHTML={{ __html: this.state.block1.content.rendered}}
                                        />
                                    }
                                    <div 
                                        className="content-column__actions"
                                    >
                                        <button 
                                            className = "btn btn--primary --active"
                                            onClick = {() => this.handleNavRoute('contact')}
                                        >
                                            Go to contact page
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockContact);