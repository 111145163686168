import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

import BlockVideo from '../blocks/block-video';

const AddressesHome = inject("stores") (
    observer (
        class AddressesHome extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {};
            }

            componentDidMount = async() => {
            }

            componentDidUpdate = async(prevProps) => {

            }

            handleNavRoute = (page) => {
                const idx = 39;
                this.storeNavigation.setCurrentPage(idx);
                //const url = '/content/' + page.toLowerCase();
                const url = '/' + page.toLowerCase();
                this.props.navigate(url);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="section section--addresses --video">
                            <div 
                                className="wrapper--blocks"
                            >
                                <div className="content-columns">
                                    <div
                                        className="content-column"
                                    >
                                        <div 
                                            className="content-column --link"
                                            onClick = {() => this.handleNavRoute('contact')}
                                        >
                                            <h2 className="content__header">Contact</h2>                     
                                            <p>info@shelterlab.world</p>
                                        </div>
                                        <div
                                             className="content-column --addresses"
                                        >
                                            <div 
                                                className="content-column --link"
                                                onClick = {() => this.handleNavRoute('contact')}
                                            >
                                                <h3>
                                                    New York City
                                                </h3>
                                                <p>
                                                    228 East 45th Street Suite 9E<br/>
                                                    NY, NY 10017<br/>
                                                    United States of America
                                                </p>
                                            </div>
                                            <div 
                                                className="content-column --link"
                                                onClick = {() => this.handleNavRoute('contact')}
                                            >
                                                <h3>Amsterdam</h3>
                                                <p>
                                                    Dorpsstraat 26<br/>
                                                    1191 BJ Ouderkerk aan de Amstel<br/>
                                                    The Netherlands
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="content-column content-column--video"
                                    >
                                        <BlockVideo
                                            url = {this.urlAPI + "public/videos/marion-bas.mp4"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(AddressesHome);