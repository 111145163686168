import { makeAutoObservable, makeObservable, observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

class StoreGeneral {
    
    listSettings = {
        maxTextArea: 250
    }
    
    constructor() {
       
    }
}

export default StoreGeneral;