import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import i18n from "i18next";

const NavigationMain = inject("stores") (
    observer (
        class NavigationMain extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;
            }

            componentDidMount = async () => {
                let menu;
                if (this.storeUi.lang === 'nl') {
                    menu = await this.storeNavigation.getMenu('menumainnl');
                } else {
                    menu = await this.storeNavigation.getMenu('menumainen');
                }
            }

            componentDidUpdate = async (prevProps) => {
                let menu;
                if (prevProps.lang !== this.props.lang) {
                    if (this.storeUi.lang === 'nl') {
                        menu = await this.storeNavigation.getMenu('menumainnl');
                    } else {
                        menu = await this.storeNavigation.getMenu('menumainen');
                    }
                }
            }

            handleHome = () => {
                this.storeNavigation.setCurrentPage(0);
                const url = '/start';
                this.props.navigate(url);
            }

            handleNav = (item) => {
                let url;
                const idx = item.object_id;
                this.storeNavigation.setCurrentPage(idx);
                this.storeNavigation.setMenuTrigger(this.props.trigger);

                if (idx === '19' || idx === '25') {
                    this.storeContent.setCurrentPageType('workshops');
                } else {
                    this.storeContent.setCurrentPageType('pages');
                }
              
                switch(item.object_id) {
                    case '2':
                        url = '/collaborations';
                        break;
                    case '8':
                        url = '/case-studies';
                        break;
                    case '24':
                        url = '/people-helping-people';
                        break;
                    case '26':
                        url = '/workshops';
                        break;
                    case '31':
                        url = '/news';
                        break;
                    case '37':
                        url = '/team';
                        break;
                    case '39':
                        url = '/contact';
                        break;
                    case '54':
                        url = '/careers';
                    break;
                    default:
                        url = '/content/' + item.title.toLowerCase();
                }
                
                this.props.navigate(url);
            }

            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);

                return (
                    <React.Fragment>
                        {/* <div 
                            className="navigation__column"
                            
                        >
                            <div 
                                className="navigation-main__header --link"
                                onClick = {() => this.handleHome()}
                            >
                                HOME
                            </div>
                        </div> */}
                        {this.storeNavigation.menuColumns.map((col) => (
                            <div 
                                className = "navigation__column"   
                            >
                                <div 
                                    className="navigation-main__header"
                                >
                                    {col.title}
                                </div>
                                {this.storeNavigation.menuMain.map((menuitem) => (
                                    col.items.includes(menuitem.ID) &&
                                        <div 
                                            className = "navigation-main__navitem --link"
                                            onClick = {() => this.handleNav(menuitem)}
                                        >
                                            {menuitem.title}
                                        </div>
                                ))}
                            </div>
                        ))}
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(NavigationMain);

