import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

const BlockPhpTerms = inject("stores") (
    observer (
        class BlockPhpTerms extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;

                this.state = {
                };
            }

            componentDidMount = async() => {
                const idx = 190;
                const content = await this.storeContent.getContentBlock('pageblocks', idx);
                console.log('SbC block', this.storeContent.currentPageType, content)

                this.setState({
                    content: content
                })
            }

            componentDidUpdate = async(prevProps) => {

            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="wrapper-content">
                            <div className="content-columns">
                                <div className="content-column content-column--main">
                                    {/* {this.state.content && this.state.content.content &&
                                        <h1 
                                            className="content__header" 
                                            dangerouslySetInnerHTML={{ __html: this.state.content.title.rendered}}
                                        />
                                    } */}
                                    {this.state.content && this.state.content.content &&
                                        <div 
                                            className="content__text" 
                                            dangerouslySetInnerHTML={{ __html: this.state.content.content.rendered}}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockPhpTerms);