import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';
import BlockJob from './block-job';

const BlockJobs = inject("stores") (
    observer (
        class BlockJobs extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    items: []
                };
            }

            componentDidMount = async() => {
                const items = await this.storeContent.getContentPages('jobs', 100);

                this.setState({
                    items: items
                })
            }

            componentDidUpdate = async(prevProps) => {
                // let items;
                // if(prevProps.items !== this.props.items){
                //     items = this.props.items;
                //     this.setState({
                //         items: items
                //     })
                // }
            }

            handleNav = (item) => {
                const idx = item.id;
                this.storeNavigation.setCurrentPage(idx);
                this.storeContent.setCurrentPageType('jobs');
                const url = '/content/' + item.title.rendered.toLowerCase();
                this.props.navigate(url);
            }

            handleNavRoute = (page) => {
                const idx = 31;
                //nl 33
                this.storeNavigation.setCurrentPage(idx);
                //const url = '/content/' + page.toLowerCase();
                const url = '/' + page.toLowerCase();
                this.props.navigate(url);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="content-column"> 
                            <ul className="content-column__list content-column__list--jobs">
                                {this.state.items.map((item) => (
                                    item.language === this.storeUi.lang &&
                                        <BlockJob
                                            item = {item}
                                        />
                                ))}
                            </ul>
 
                            {this.props.linkAll &&
                                <button 
                                    className = "btn btn--primary --active"
                                    onClick = {() => this.handleNavRoute('news')}
                                >
                                    View all career openings
                                </button>
                            }
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockJobs);