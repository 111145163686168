import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

import BlockTeammember from './block-teammember';

const BlockTeam = inject("stores") (
    observer (
        class BlockTeam extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    items: []
                };
            }

            componentDidMount = async() => {
                const items = await this.storeContent.getContentPages('teammembers', 100);

                const itemsSorted = [].concat(items)
                    .sort((a, b) => parseInt(a.acf.ordering) > parseInt(b.acf.ordering) ? 1 : -1)

                console.log('SbC team', itemsSorted)
                this.setState({
                    items: itemsSorted
                })
            }

            componentDidUpdate = async(prevProps) => {
                // let items;
                // if(prevProps.items !== this.props.items){
                //     items = this.props.items;
                //     this.setState({
                //         items: items
                //     })
                // }
            }

            handleNav = (item) => {
                const idx = item.id;
                this.storeNavigation.setCurrentPage(idx);
                this.storeContent.setCurrentPageType('teammembers');
                const url = '/content/' + item.title.rendered.toLowerCase();
                this.props.navigate(url);
            }

            handleNavRoute = (page) => {
                const idx = 37;
                this.storeNavigation.setCurrentPage(idx);
                //const url = '/content/' + page.toLowerCase();
                const url = '/' + page.toLowerCase();
                this.props.navigate(url);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="content-column">
                            <h2>All teammembers</h2>
                            <p>xxxx x  x x  x  x x x xxxxxx xx x</p>
                        </div>
                        <div className="content-column">
                            <h2>Team</h2>
                            <ul className="content-column__list content-column__list--teammembers">
                                {this.state.items.map((item) => (
                                    item.language === this.storeUi.lang && item.acf.member_group !== 'advisor' &&
                                        <BlockTeammember
                                            member = {item}
                                        />
                                ))}
                            </ul>
                        </div>

                        <div className="content-column">
                            <h2>Advisors</h2>
                            <ul className="content-column__list content-column__list--teammembers">
                                {this.state.items.map((item) => (
                                    item.language === this.storeUi.lang && item.acf.member_group === 'advisor' &&
                                        <BlockTeammember
                                            member = {item}
                                        />
                                ))}
                            </ul>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockTeam);