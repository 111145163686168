import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../utilities/withRouter';

import * as ui from '../utilities/ui';
import Template from '../templates/template';

import BlockCases from '../components/blocks/block-cases';
import ColumnMainCase from '../components/columns/column-main-case';

const CaseStudy = inject("stores") (
    observer (
        class CaseStudy extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                // this.env = process.env.REACT_APP_ENV;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;   
                this.storeContent = this.props.stores.storeContent;  
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                   
                }  
            }

            componentDidMount = async () => {
                ui.handleScroll('top');
                let slug = '';
                let route = '';
                let currentPage;

                const idx = this.storeNavigation.idCurrentPage;   
                this.storeContent.setCurrentPageType('casestudy');      
                const content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, idx);

                if (Array.isArray(content)) {
                    content.map((page) => {
                        if (this.props.params.case.toLowerCase() === page.title.rendered.toLowerCase()) {
                            currentPage = page;
                        }
                    })
                } else {
                    currentPage = content;
                }

                let seo = content.yoast_head_json;
                let acf = content.acf;

                this.setState({
                    idx: idx,
                    case: this.props.params.case,
                    route: route,
                    content: currentPage,
                    slug: slug,
                    acf: acf,
                    seo: seo
                })
            }

            componentDidUpdate = async(prevProps, prevState) => {
                let currentPage;

                if (this.storeNavigation.idCurrentPage !== this.state.idx) {
                    const idx = this.storeNavigation.idCurrentPage;
                    const content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, idx);

                    if (Array.isArray(content)) {
                        content.map((page) => {
                            if (this.props.params.case.toLowerCase() === page.title.rendered.toLowerCase()) {
                                currentPage = page;
                            }
                        }) 
                    } else {
                        currentPage = content;
                    }  
                    
                    this.setState({
                        content: currentPage
                    })
                }
            }

            gotoBlock = () => {
                //ui.smoothScroll(this.blockRef.offsetTop - 40);
            }

            onSize = (size) => {
                //console.log('SbC MyComponent has a height of', size.height)
            }

            render() {
                const title = (this.state.content && this.state.content.title) ? this.state.content.title.rendered : ""

                return (
                    <Template
                        title = {"Case | " + title}
                        canonical = {"case/" + title}
                    >
                        <div className="wrapper-content wrapper-content--rotated wrapper-content--case">
                            <div className="content-columns">
                                <ColumnMainCase
                                    id = {this.state.idx}
                                    content = {this.state.content}
                                    acf = {this.state.acf}
                                    onSize={this.onSize}
                                />
                                <div className="content-column content-column--secundary">
                                    
                                    {this.state.content && this.state.content.content &&
                                        <div 
                                            className="wp-content__text" 
                                            dangerouslySetInnerHTML={{ __html: this.state.content.content.rendered}}
                                        />
                                    }
                                </div>
                            </div>
                        </div>

                        <BlockCases 
                            intro = {true}
                            grid = {'--full'}
                            rotated = {true}
                        />
                    </Template>
                );
            }
        }
    )
)

export default withRouter(CaseStudy);
