import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import i18n from "i18next";
import {withRouter} from '../utilities/withRouter';

const LandingPage = inject("stores") (
    observer (
        class LandingPage extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi; 
                this.storeNavigation = this.props.stores.storeNavigation;
                
                this.bg = "im-landingpage.jpg";
            }

            componentDidMount() {
                this.currentLang = this.props.stores.storeUi.app_interface.lang;
            }

            handleHome = () => {
                this.storeNavigation.setCurrentPage(0);
                const url = '/start';
                this.props.navigate(url);
            }

            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);
                
                return (
                    <div 
                        className="wrapper-content wrapper-content--landingpage"
                        style= {{backgroundImage:`url(${this.urlAPI + "public/images/landingpage/" + this.bg})` }}
                    >
                        <div 
                            className="landingpage__header --link"
                            onClick = {() => this.handleHome()}
                        >
                            <h1>Start here</h1>
                        </div>
                    </div>
                );
            }
        }
    )
);

export default withRouter(LandingPage);