import { makeAutoObservable, makeObservable, observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as content from '../communicator/content';
import * as pages from '../communicator/pages';
import * as menus from '../communicator/menus';

class StoreContent {
    app_interface = { 
        lang: '',
        lang_id: 0
    }  
    
    content_pages = [];
    content_cases = [];
    content_blocks = [];
    content_blogs = [];
    content_collaborations = [];
    content_news = [];
    content_jobs = [];
    content_teammembers = [];

    current_page = {};
    currentPageType = '';

    constructor() {
        makeObservable(this, {
            content_collaborations: observable,
            content_pages: observable,
            content_cases: observable,
            content_blocks: observable,
            content_blogs: observable,
            content_news: observable,
            content_jobs: observable,
            content_teammembers: observable,
            currentPageType: observable
        })
        this.getContentPages('pages', 100);
        this.getContentPages('pageblocks', 100);
    }

    setCurrentPage(data) {
        this.current_page = data;
    }

    setCurrentPageType = (value) =>{
        this.currentPageType = value;
    }

    async getContentBlock(section, id) {
        let data = {};

        try {
            const returnData = await content.getContentBlock(section, id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    data = returnData;
                } 
                return data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return data;
    }

    async getContentSingle(type, id) {
        let contentSingle = {};
        try {
            const returnData = await content.getContentSingle(type, id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    contentSingle = returnData;
                    this.current_page = returnData;
                } 
                return contentSingle;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return contentSingle;
    }

    async getContentPage(id) {
        let content = {};
        try {
            const returnData = await pages.getContentPage(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    content = returnData;
                    this.current_page = returnData;
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }

    async getContentDialog(id) {
        let content = {};
        try {
            const returnData = await pages.getContentPage(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    content = returnData;
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }

    async getContentPages(section, amount) {
        let content = {};
        try {
            const returnData = await pages.getContentPages(section, amount);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    content = returnData;

                    if (section === 'casestudies') {
                        this.content_cases = returnData;
                    }
                    if (section === 'pages') {
                        this.content_pages = returnData;
                    }
                    if (section === 'pageblocks') {
                        this.content_blocks = returnData;
                    }
                    if (section === 'collaborations') {
                        this.content_collaborations = returnData;
                    }
                    if (section === 'workshops') {
                        this.content_workshops = returnData;
                        console.log('SbC worshops', returnData)
                    }
                    if (section === 'teammembers') {
                        this.content_teammembers = returnData;
                        console.log('SbC team', returnData)
                    }
                    if (section === 'jobs') {
                        this.content_jobs = returnData;
                        console.log('SbCjobs', returnData)
                    }
                    if (section === 'news') {
                        this.content_news = returnData;
                        console.log('SbC  news', returnData)
                    }
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }

    async getContentMedia(id) {
        let returnMedia = {};

        try {
            const returnData = await content.getContentMedia(id);
            runInAction(() => { 
                returnMedia = returnData.source_url;
                return returnMedia;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return returnMedia;
    }

    async getCustomFields(id) {
        let content = {};
        try {
            const returnData = await pages.getCustomFields(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }
}

export default StoreContent;