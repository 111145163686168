import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import {withRouter} from '../utilities/withRouter';
import i18n from "i18next";
// import MetaTags from 'react-meta-tags';

import * as ui from '../utilities/ui';
import Template from '../templates/template';
import BlockWorkshops from '../components/blocks/block-workshops';

const ContentPage = inject("stores") (
    observer (
        class ContentPage extends React.Component {

            constructor(props) {
                super(props);
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    slug: '',
                    pageblocks: []
                };

                //this.blockRef = React.createRef();
            }

            componentDidMount = async () => {
                let slug = '';
                let currentPage;

                ui.handleScroll('top');

                const idx = this.storeNavigation.idCurrentPage;

                this.storeContent.setCurrentPageType('blocks');
                const content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, idx);

                this.storeContent.setCurrentPageType('pages');
                const content2 = await this.storeContent.getContentPages(this.storeContent.currentPageType, 100);
                console.log('SbC current page type', content2, this.props)
                content2.map((page) => {
                    if (this.props.params.title.toLowerCase() === page.title.rendered.toLowerCase()) {
                        currentPage = page;
                        console.log('SbC content2', page)
                    }
                })
                
                this.setState({
                    content: currentPage,
                    content2: content2
                })
            }

            componentDidUpdate = async (prevProps) => {
                let content;
                let content2;
                let currentPage;

                if (prevProps.idx !== this.props.idx) {
                    console.log('SbC getting pages')
                    content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, this.props.idx);
                    
                    this.storeContent.setCurrentPageType('pages');
                    const content2 = await this.storeContent.getContentPages(this.storeContent.currentPageType, 100);
                    console.log('SbC current page type', content2, this.props)
                       
                    content2.map((page) => {
                        if (this.props.params.title.toLowerCase() === page.title.rendered.toLowerCase()) {
                            currentPage = page;
                            console.log('SbC content2', page)
                        }
                    })
               
                    this.setState({
                        content: currentPage,
                        content2: content2
                    })
                }

                let lang;
                if (prevProps.lang !== this.props.lang) {
                    if (this.props.lang === 'nl') {
                        lang = "nl_NL";
                    }
                    if (this.props.lang === 'en') {
                        lang = "en_GB";
                    }
                    const langs = this.state.content.polylang_translations;
                    const obj = langs.find(o => o.locale === lang);
                    const idx = obj.id;
                    content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, idx);

                    this.setState({
                        content: content
                    })
                }
            }

            gotoBlock = () => {
                ui.smoothScroll(this.blockRef.offsetTop - 40);
            }

            render() {
                return (
                    <Template
                        title = {"Content"}
                        canonical = {"content"}
                    >
                        <div className="wrapper-content wrapper-content--rotated">
                            <div className="content-columns">
                                <div className="content-column content-column--main">
                                    <div
                                        className="content__header content__header--rotated" 
                                    >
                                        <span className="rotated-text">
                                            <span 
                                                className="rotated-text__inner"
                                                
                                            >
                                                {this.state.content && this.state.content.content &&
                                                    this.state.content.title.rendered
                                                }
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className="page-content content-column--secundary">
                                    {this.state.content && this.state.content.content &&
                                        <div 
                                            className="wp-content__text" 
                                            dangerouslySetInnerHTML={{ __html: this.state.content.content.rendered}}
                                        />
                                    }
                                </div>
                            </div>
                        </div>

                    </Template>
                )
            }
        }
    )
)

export default withRouter(ContentPage);

