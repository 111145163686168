import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

const BlockOurWork = inject("stores") (
    observer (
        class BlockOurWork extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.items = [{
                    sku: 'WORKSHOPS',
                    title: 'Meaningful work',
                    intro: 'xxxx',
                    link: '/workshops',
                    linkTxt: 'Social impact strategies, workforce<br/>innovation, employee engagement',
                    id: 25
                },{
                    sku: 'COLLABORATIONS',
                    title: 'Repurpose for purpose',
                    intro: 'xxxx',
                    link: '/collaborations',
                    linkTxt: 'Design collaborations,<br/>environmental and social impact',
                    id: 25
                },{
                    sku: 'PEOPLE',
                    title: 'New Venture Development',
                    intro: 'xxxx',
                    link: '/social-enterprises',
                    linkTxt: 'Regenerative social enterprises,<br/>corporate venturing',
                    id: 25
                }];
                
                this.state = {
                   
                }
            }

            componentDidMount = async() => {
                let idx;
                // idx = 63; //NL DEV
                // idx = 172; //EN DEV
                // idx = 33 //NL
                idx = 72; //EN

                const block1 = await this.storeContent.getContentBlock('pageblocks', idx);

                this.setState({
                    block1: block1
                })
            }

            componentDidUpdate = async(prevProps) => {
                // let items;
                // if(prevProps.items !== this.props.items){
                //     items = this.props.items;
                //     this.setState({
                //         items: items
                //     })
                // }

            }

            handleNav = (item) => {
                const idx = item.id;
                this.storeNavigation.setCurrentPage(idx);
                this.storeContent.setCurrentPageType('workshops');
                //const url = '/content/' + item.title.rendered.toLowerCase();
                const url = item.link;
                this.props.navigate(url);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="section section--work">
                            <div className="wrapper--blocks">
                                <div className="content-columns">
                                    <div className="content-column content-column--main">
                                        {this.state.block1 && this.state.block1.content &&
                                            <h1 
                                                className="content__header" 
                                                dangerouslySetInnerHTML={{ __html: this.state.block1.title.rendered}}
                                            />
                                        }
                                        {this.state.block1 && this.state.block1.content &&
                                            <div 
                                                className="content__text" 
                                                dangerouslySetInnerHTML={{ __html: this.state.block1.content.rendered}}
                                            />
                                        }
                                    </div>
                                    <div className="content-column content-column--blocks">
                                        <ul>
                                            {this.items.map((item) => (
                                                <li 
                                                    className="ankeiler ankeiler--work --link"
                                                    onClick = {() => this.handleNav(item)}
                                                >
                                                    <div className="content-column__listitem-content">
                                                        <h2 
                                                            className = "content__header" 
                                                            dangerouslySetInnerHTML = {{ __html: item.title}}
                                                        />
                                                        <div 
                                                            className = "ankeiler__link"
                                                            dangerouslySetInnerHTML = {{ __html: item.linkTxt}}
                                                        />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockOurWork);