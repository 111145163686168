import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../utilities/withRouter';
import i18n from "i18next";

import * as ui from '../utilities/ui';
import Template from '../templates/template';
import BlockCases from '../components/blocks/block-cases';
import BlockContact from '../components/blocks/block-contact';
import BlockOurWork from '../components/blocks/block-ourwork';
import BlockNewsletter from '../components/blocks/block-newsletter';
import BlockShelterLab from '../components/blocks/block-shelter-lab';
import BlockNews from '../components/blocks/block-news';
import BlockPeopleHelpingPeople from '../components/blocks/block-peoplehelpingpeople';
import BlockSheltersuitFoundation from '../components/blocks/block-sheltersuitfoundation';
import BlockTicker from '../components/blocks/block-ticker';

const Start = inject("stores") (
    observer (
        class Start extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                // this.env = process.env.REACT_APP_ENV;
                // this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;   
                this.storeContent = this.props.stores.storeContent;  

                this.state = {
                    content: [],
                    content2: []
                }; 
            }

            componentDidMount = async() => {
                let key;
                if (this.storeUi.lang === 'nl') {
                    key = 37;
                }
                if (this.storeUi.lang === 'en') {
                    key = 34;
                }
                const content = await this.storeContent.getContentBlock('pages', key);
                //console.log('SbC content', key, content)

                this.setState({
                    content: content
                })
            }

            componentDidUpdate = async(prevProps) => {
                let key;

                if (prevProps.lang !== this.props.lang) {
                    if (this.storeUi.lang === 'nl') {
                        key = 37;
                    }
                    if (this.storeUi.lang === 'en') {
                        key = 34;
                    }
                    const content = await this.storeContent.getContentBlock('pages', key);
                    console.log('SbC content', key, content)

                    this.setState({
                        content: content
                    })
                }
            }

            gotoBlock = () => {
                //ui.smoothScroll(this.blockRef.offsetTop - 40);
            }

            render() {
                return (
                    <Template
                        title = {"Homepage | ShelterLab"}
                        canonical = {"start"}
                        page = {"start"}
                    >
                        <div>
                            <div>
                                <BlockOurWork

                                />
                            </div>

                            {/* <div>
                                <BlockNewsletter

                                />
                            </div> */}

                            {/* <div>
                                <BlockShelterLab

                                />
                            </div> */}

                            <div>
                                <BlockCases
                                    intro = {true}
                                    grid = {'--column'}
                                    rotated = {false}
                                />
                            </div>

                            <div>
                                <BlockTicker

                                />
                            </div>

                            <div>
                                <BlockPeopleHelpingPeople

                                />
                            </div>

                            <div>
                                <BlockSheltersuitFoundation

                                />
                            </div>

                            <div>
                                <BlockContact

                                />
                            </div>

                            <div>
                                <BlockNews
                                    linkAll = {true}
                                    grid = {'--full'}
                                    view = "4"
                                    format = "--rectangle"
                                />
                            </div>
                        </div>
                    </Template>
                );
            }
        }
    )
)

export default Start;
