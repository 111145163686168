import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

const BlockVideo = inject("stores") (
    observer (
        class BlockVideo extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.video = this.props.url;
                this.videoEl = React.createRef();
            }

            componentDidMount = async() => {
                this.attemptPlay();
            }

            componentDidUpdate = async(prevProps) => {
               
            }

            attemptPlay = () => {
                this.videoEl &&
                this.videoEl.current &&
                this.videoEl.current.play().catch(error => {
                    console.error("Error attempting to play", error);
                });
            }

            render() {
                return (
                    <React.Fragment>
                        <div className="wrapper--video">
                            {/* <BlockVideo
                                video = {this.video}
                                isDialogVideoOpen = {this.storeUi.isDialogVideoOpen}
                                orientation = {'landscape'}
                                active_video = {this.storeUi.active_video}
                                video_playing = {this.state.video_playing}
                                handleVideoPlaying = {this.handleVideoPlaying}
                                ref={this.videoEl} 
                            /> */}
                            <video
                                style={{ maxWidth: "100%", margin: "0" }}
                                playsInline
                                loop
                                muted
                                controls
                                alt="All the devices"
                                src={this.video}
                                ref={this.videoEl}
                                controlsList="nodownload"
                            />
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockVideo);