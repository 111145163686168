import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

import BlockVideo from '../blocks/block-video';

const BlockSheltersuitFoundation = inject("stores") (
    observer (
        class BlockSheltersuitFoundation extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.video = this.urlAPI + "/public/videos/the-chloe-autumn-winter-2021-show.mp4";
                
                this.state = {
                    items: []
                };
            }

            componentDidMount = async() => {
                let idx;
                // idx = 63; //NL DEV
                // idx = 172; //EN DEV
                // idx = 33 //NL
                idx = 170; //EN
                const block1 = await this.storeContent.getContentBlock('pageblocks', idx);
                const featuredImage = block1.better_featured_image.media_details.sizes.medium.source_url;
                let acf = block1.acf;
              
                this.setState({
                    block1: block1,
                    featuredImage: featuredImage,
                    acf: acf
                })
            }

            componentDidUpdate = async(prevProps) => {
                let items;
                if(prevProps.items !== this.props.items){
                    items = this.props.items;
                    this.setState({
                        items: items
                    })
                }

            }

            handleNav = (item) => {
                const idx = item.id;
                this.storeNavigation.setCurrentPage(idx);
                this.storeContent.setCurrentPageType('workshops');
                const url = '/content/' + item.title.rendered.toLowerCase();
                this.props.navigate(url);
            }

            handleNavRoute = (page) => {
                const idx = 191;
                this.storeNavigation.setCurrentPage(idx);
                //const url = '/content/' + page.toLowerCase();
                const url = '/' + page.toLowerCase();
                this.props.navigate(url);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="section section--foundation">
                            <div className="wrapper--blocks">
                                <div className="content-columns">
                                    <div className="content-column content-column--secundary">
                                        <BlockVideo
                                            url = {this.video}
                                        />
                                    </div>
                                    <div className="content-column content-column--main">
                                        <div 
                                            className="content-column__content"
                                            //style= {{backgroundImage:`url(${this.state.featuredImage})` }}
                                        >
                                            {/* <div
                                                className="content-column__bgoverlay" 
                                            /> */}
                                            <div
                                                className="content-column__wp-content"
                                            >
                                                {this.state.block1 && this.state.block1.content &&
                                                    <h2 
                                                        className="content__header" 
                                                        dangerouslySetInnerHTML={{ __html: this.state.block1.title.rendered}}
                                                    />
                                                }
                                                {this.state.block1 && this.state.block1.content &&
                                                    <div 
                                                        className="content__text" 
                                                        dangerouslySetInnerHTML={{ __html: this.state.block1.content.rendered}}
                                                    />
                                                }
                                                <div 
                                                    className="content-column__actions"
                                                >
                                                    <button 
                                                        className = "btn btn--primary --active"
                                                        onClick = {() => this.handleNavRoute('sheltersuit-foundation')}
                                                    >
                                                        {this.state.acf && this.state.acf.button_text}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockSheltersuitFoundation);