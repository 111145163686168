import { makeAutoObservable, makeObservable, observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

class StoreIds {
    blocks = [{
        sku: 'PHP',
        id: 21
    },{
        sku: 'SHELTER',
        id: 22
    },{
        sku: 'LAB',
        id: 22
    }

    ]
    
    constructor() {

    }
}

export default StoreIds;