import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Routes, Route } from 'react-router-dom';
import {withRouter} from './utilities/withRouter';

import UnderConstruction from './pages/under-construction';
import SettingLanguage from './pages/setting-language';
import ContentPage from './pages/content';

import Start from './pages/start';
import AboutUs from './pages/about-us';
import Article from './pages/news-article';
import Workshop from './pages/workshop';
import Workshops from './pages/workshops';
import Career from './pages/career';
import Careers from './pages/careers';
import Contact from './pages/contact';
import News from './pages/news';
import OurWork from './pages/our-work';
import Team from './pages/team';
import Collaborations from './pages/collaborations';
import CaseStudies from './pages/case-studies';
import CaseStudy from './pages/case';
import PeopleHelpingPeople from './pages/people-helping-people';
import SocialEnterprises from './pages/social-enterprises';
import SheltersuitFoundation from './pages/sheltersuit-foundation';
import LandingPage from './pages/landingpage';


const AppRoutes = inject("stores") (
    observer (
        class AppRoutes extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi; 
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation; 
            }

            render() {
                return (
                    <React.Fragment>
                        <Routes>
                            <Route 
                                path='/' 
                                element={
                                    <LandingPage 
                                        lang = {this.storeUi.lang} 
                                    />
                                }
                            />
                            <Route 
                                path='/landingpage' 
                                element={<LandingPage />}
                            />
                            <Route 
                                path='/start' 
                                element={
                                    <Start 
                                        lang = {this.storeUi.lang} 
                                    />
                                }
                            />
                            <Route 
                                path='/about-us' 
                                element={<AboutUs />}
                            />
                            <Route 
                                path='/articles/:title' 
                                element={<Article />}
                            />
                            <Route 
                                path='/workshop/:title' 
                                element={<Workshop />}
                            />
                            <Route 
                                path='/workshops' 
                                element={<Workshops />}
                            />
                            <Route 
                                path='/career/:job' 
                                element={<Career />}
                            />
                            <Route 
                                path='/careers' 
                                element={<Careers />}
                            />
                            <Route 
                                path='/contact' 
                                element={<Contact />}
                            />
                            <Route 
                                path='/news' 
                                element={<News />}
                            />
                            <Route 
                                path='/case-studies' 
                                element={<CaseStudies />}
                            />
                            <Route 
                                path='/case/:case' 
                                element={<CaseStudy />}
                            />
                            <Route 
                                path='/our-work' 
                                element={<OurWork />}
                            />
                            <Route 
                                path='/people-helping-people' 
                                element={<PeopleHelpingPeople />}
                            />
                            <Route 
                                path='/sheltersuit-foundation' 
                                element={<SheltersuitFoundation />}
                            />
                             <Route 
                                path='/team' 
                                element={<Team />}
                            />
                            <Route 
                                path='/collaborations' 
                                element={<Collaborations />}
                            />
                            <Route 
                                path='/social-enterprises' 
                                element={<SocialEnterprises />}
                            />
                            <Route 
                                path='/set-language' 
                                element={<SettingLanguage />}
                            />
                            <Route 
                                path = "/content/:title" 
                                element = {
                                    <ContentPage
                                        type = {this.storeContent.currentPageType}
                                        idx = {this.storeNavigation.idCurrentPage}
                                        lang = {this.storeUi.lang} 
                                    />
                                } 
                            />
                        </Routes>
                    </React.Fragment>
                );
            }
        }
    )
)

export default withRouter(AppRoutes);