import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

const BlockWorkshops = inject("stores") (
    observer (
        class BlockWorkshops extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    items: []
                };
            }

            componentDidMount = async() => {
                const items = await this.storeContent.getContentPages('workshops', 100);
                console.log('SbC coll', items)
                this.setState({
                    items: items
                })
            }

            componentDidUpdate = async(prevProps) => {
                // let items;
                // if(prevProps.items !== this.props.items){
                //     items = this.props.items;
                //     this.setState({
                //         items: items
                //     })
                // }
            }

            handleNav = (item) => {
                const idx = item.id;
                this.storeNavigation.setCurrentPage(idx);
                this.storeContent.setCurrentPageType('workshops');
                const url = '/workshop/' + item.title.rendered.toLowerCase();
                this.props.navigate(url);
            }
      
            render() {
                return (
                    <div className="section section--workshops">
                        <div className={"content-columns " + this.props.grid}>
                            <div className="content-column content-column--blocks">
                                <ul>
                                    {this.state.items.map((item) => (
                                        item.language === this.storeUi.lang &&
                                        <li 
                                            className="ankeiler ankeiler--workshop --link"
                                            onClick = {() => this.handleNav(item)}
                                        >
                                            <div 
                                                className="content-column__listitem-content"
                                                //style= {{backgroundImage:`url(${item.better_featured_image && item.better_featured_image.media_details.sizes.large.source_url})` }}
                                            >
                                                
                                                {/* <div 
                                                    className = "ankeiler__link --link"
                                                    onClick = {() => this.handleNav(item)}
                                                >
                                                    Read more
                                                </div> */}
                                            </div>
                                            <h2 
                                                className = "content__header" 
                                                dangerouslySetInnerHTML = {{ __html: item.title.rendered}}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(BlockWorkshops);