import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../utilities/withRouter';

import * as ui from '../utilities/ui';
import Template from '../templates/template';

import BlockWorkshops from '../components/blocks/block-workshops';

const Workshops = inject("stores") (
    observer (
        class Workshops extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                // this.env = process.env.REACT_APP_ENV;
                // this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;   
                this.storeContent = this.props.stores.storeContent;  

                this.state = {
                   
                }  
            }

            componentDidMount = async () => {
                ui.handleScroll('top');
                let slug = '';
                let route = '';

                // const route = this.props.match.path;
                // const slug = route.substring(1);

                if (this.storeContent.content_pages.length === 0) {
                    let pages = await this.storeContent.getContentPages('pages', 100);
                    console.log('SbC pages', pages)
                }

                //const page =  this.storeContent.content_pages.find(page => page.slug === slug);

                let idx;
                // idx = 25; //NL DEV
                // idx = 19; //EN DEV
                // idx = 28 //NL
                idx = 26; //EN

                //const idx = page.id;
                let content = await this.storeContent.getContentPage(idx);
                console.log('SbC page 1', content)

                let seo = content.yoast_head_json;
                let acf = content.acf;

                this.setState({
                    route: route,
                    content: content,
                    slug: slug,
                    acf: acf,
                    seo: seo
                })
            }

            componentDidUpdate = async() => {
               
            }

            gotoBlock = () => {
                //ui.smoothScroll(this.blockRef.offsetTop - 40);
            }

            render() {
                return (
                    <Template
                        title = {"Workshops"}
                        canonical = {"workshop"}
                    >
                        <div className="wrapper-content wrapper-content--rotated">
                            <div className="content-columns">
                                <div className="content-column content-column--main">
                                    <div
                                        className="content__header content__header--rotated" 
                                    >
                                        <span className="rotated-text">
                                            <span 
                                                className="rotated-text__inner"
                                                
                                            >
                                                Meaningful work
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className="content-column content-column--secundary">
                                     {this.state.content && this.state.content.content &&
                                        <div 
                                            className="wp-content__text" 
                                            dangerouslySetInnerHTML={{ __html: this.state.content.content.rendered}}
                                        />
                                    }
                                </div>
                            </div>

                            <BlockWorkshops 
                                grid = {"--full"}
                             />
                        </div>
                    </Template>
                );
            }
        }
    )
)

export default Workshops;
