import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import i18n from "i18next";

const NavigationUtilities = inject("stores") (
    observer (
        class NavigationUtilities extends Component {

            constructor(props) {
                super(props);
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;
            }

            componentDidMount = async () => {
                i18n.changeLanguage(this.storeUi.app_interface.lang);

                let menu;
                // if (this.storeUi.lang === 'nl') {
                //     menu = await this.storeNavigation.getMenu('menumainnl');
                // } else {
                    menu = await this.storeNavigation.getMenu('menuutilities');
                //}
            }

            componentDidUpdate = (prevProps) => {
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            handleNav = (item) => {
                let url;
                const idx = item.object_id;
                this.storeNavigation.setCurrentPage(idx);
                this.storeContent.setCurrentPageType('pages');
                this.storeNavigation.setMenuTrigger(this.props.trigger);

                url = '/content/' + item.title.toLowerCase();
                this.props.navigate(url);
            }

            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);

                return (
                    <div className="wrapper--navigation-utilities">
                        <ul className="navigation-utilities__list">
                            {/* {this.storeNavigation.utilities.map((utility) => (
                                <li>{i18n.t("navigation." + utility.i18n)}</li>
                            ))} */}
                            {this.storeNavigation.menuUtilities.map((menuitem) => (
                                <li 
                                    className = "--link"
                                    onClick = {() => this.handleNav(menuitem)}
                                >
                                    {menuitem.title}
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(NavigationUtilities);

