import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';
import Ticker from 'react-awesome-ticker';

const BlockTicker = inject("stores") (
    observer (
        class BlockTicker extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    items: []
                };
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = async(prevProps) => {
               
            }

            render() {
                return (
                    <React.Fragment>
                        <div className="wrapper--ticker">
                            <div
                                className = "section section--ticker" 
                            >
                                <Ticker>
                                    <div className="content-column">
                                        <span>People</span>
                                        <span>helping</span>
                                    </div>
                                </Ticker>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockTicker);