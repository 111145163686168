import { makeAutoObservable, makeObservable, observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';

class StoreUi {
    app_interface = { 
        lang: '',
        lang_id: 0,
        height_footer: 0,
        isDialogContentOpen: false
    }  
    lang = 'en';
    lang_id = 1;

    menu_mobile = false
    content_component = ''

    constructor() {
        makeObservable(this, {
            lang: observable
        })
    }
    
    closeDialogContent = () => {
        this.app_interface.isDialogContentOpen = false;
    }

    openDialogContent = () => {
        this.app_interface.isDialogContentOpen = true;
    }

    setContentComponent(comp) {
        this.content_component = comp;
    }
    
    setHeightFooter(num) {
        this.app_interface.height_footer = num;
    }

    setMobileMenu() {
        this.menu_mobile = !this.menu_mobile;
    }

    setLocale(lang) {
        this.app_interface.lang = lang;
        this.lang = lang;
        switch(lang) {
            case 'nl':
               this.app_interface.lang_id = 0;
              break;
            default:
               this.app_interface.lang_id = 1;
        } 
    }
}

export default StoreUi;