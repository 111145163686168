import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../utilities/withRouter';

import * as ui from '../utilities/ui';
import Template from '../templates/template';

import BlockNewsNavigation from '../components/blocks/block-news-navigation';
import Slideshow from '../elements/slideshow';

const Workshop = inject("stores") (
    observer (
        class Workshop extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                // this.env = process.env.REACT_APP_ENV;
                // this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;   
                this.storeContent = this.props.stores.storeContent;  
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                   
                }  
            }

            componentDidMount = async() => {
                ui.handleScroll('top');
                let slug = '';
                let route = '';
                let currentPage;

                const idx = this.storeNavigation.idCurrentPage;   
                this.storeContent.setCurrentPageType('workshops');      
                const content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, idx);

                if (Array.isArray(content)) {
                    content.map((page) => {
                        if (this.props.params.title.toLowerCase() === page.title.rendered.toLowerCase()) {
                            currentPage = page;
                        }
                    })
                } else {
                    currentPage = content;
                }

                let seo = content.yoast_head_json;
                let acf = content.acf;

                this.setState({
                    idx: idx,
                    route: route,
                    content: currentPage,
                    slug: slug,
                    acf: acf,
                    seo: seo
                })
            }

            componentDidUpdate = async(prevProps) => {
                console.log('SbC new workshop')
                if (prevProps.params.title.toLowerCase() !== this.props.params.title.toLowerCase() ) {
                    console.log('SbC new workshop')
                }
               
            }

            gotoBlock = () => {
                //ui.smoothScroll(this.blockRef.offsetTop - 40);
            }

            render() {
                return (
                    <Template
                        title = {"Workshop"}
                        canonical = {"workshop"}
                    >
                        <div className="wrapper-content wrapper-content--news-article">
                            <div className="content-columns">
                                <div className="content-column content-column--main --sticky">
                                    {this.state.content && this.state.content.content &&
                                        <div
                                        className="content__header content__header--rotated" 
                                    >
                                        <span className="rotated-text">
                                            <span 
                                                className="rotated-text__inner"
                                                dangerouslySetInnerHTML={{ __html: this.state.content.title.rendered}}
                                            />
                                        </span>
                                    </div>
                                    }
                                </div>

                                <div className="content-column content-column--secundary">
                                    {this.state.content && this.state.content.content &&
                                        <div 
                                            className="wp-content__text" 
                                            dangerouslySetInnerHTML={{ __html: this.state.content.content.rendered}}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </Template>
                );
            }
        }
    )
)

export default  withRouter(Workshop);
