import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

const BlockTeamMember = inject("stores") (
    observer (
        class BlockTeamMember extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {

                };
            }

            componentDidMount = async() => {
                const featuredImage = await this.storeContent.getContentMedia(this.props.member.featured_media);

                this.setState({
                    featuredImage: featuredImage
                })
            }

            componentDidUpdate = async(prevProps) => {
                // let items;
                // if(prevProps.items !== this.props.items){
                //     items = this.props.items;
                //     this.setState({
                //         items: items
                //     })
                // }
            }

            handleNav = (item) => {
                const idx = item.id;
                this.storeNavigation.setCurrentPage(idx);
                this.storeContent.setCurrentPageType('teammembers');
                const url = '/content/' + item.title.rendered.toLowerCase();
                this.props.navigate(url);
            }

            handleNavRoute = (page) => {
                const idx = 37;
                this.storeNavigation.setCurrentPage(idx);
                //const url = '/content/' + page.toLowerCase();
                const url = '/' + page.toLowerCase();
                this.props.navigate(url);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <li>
                            <div className="content-column__listitem-content">
                                <img 
                                    src = {this.state.featuredImage}
                                />
                                <h2 
                                    className = "content__header --link" 
                                    dangerouslySetInnerHTML = {{ __html: this.props.member.title.rendered}}
                                    onClick = {() => this.handleNav(this.props.member)}
                                />
                                <span>{this.props.member.acf.position}</span>
                                <span>{this.props.member.acf.url_linkedin}</span>
                            </div>
                        </li>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockTeamMember);