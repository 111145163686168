import * as apifetch from './fetch.js';

export async function sendFormContact(form, data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + 'email/contact';

    const postData = data;

    return apifetch.shelterFetch(urlEndpoint, 'POST', postData);
}

export async function sendFormNewsletter(form, data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + 'email/newsletter';

    const postData = data;

    return apifetch.shelterFetch(urlEndpoint, 'POST', postData);
}