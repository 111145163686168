import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';
import sizeMe from 'react-sizeme';

import * as ui from '../../utilities/ui';

const ColumnMainCase = inject("stores") (
    observer (
        class ColumnMainCase extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                // this.env = process.env.REACT_APP_ENV;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;   
                this.storeContent = this.props.stores.storeContent;  
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                   
                }  
            }

            componentDidMount = async () => {
                // ui.handleScroll('top');
                // let slug = '';
                // let route = '';

                // const idx = this.storeNavigation.idCurrentPage;
                // const content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, idx);
                // console.log('SbC case', this.storeContent.currentPageType, content)

                // let seo = content.yoast_head_json;
                // let acf = content.acf;

                // this.setState({
                //     idx: idx,
                //     route: route,
                //     content: content,
                //     slug: slug,
                //     acf: acf,
                //     seo: seo
                // })
                
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.id !== this.props.id) {
                    //const { width, height } = this.props.size;
                    console.log('SbC height:', this.props)
                }
                // const idx = this.storeNavigation.idCurrentPage;

                // //if (this.prevState.idx !== idx) {
                    

                //     const content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, idx);

                //     this.setState({
                //         idx: idx,
                //         content: content
                //     })                   
                // //}
            }

            // componentDidUpdate = () => {
                
            //     // this.setState({
            //     //     heightColumn: this.props.size.height
            //     // });
            // }

            gotoBlock = () => {
                //ui.smoothScroll(this.blockRef.offsetTop - 40);
            }

            render() {
                //const { width, height } = this.props.size

                return (
                    <div className="content-column content-column--main --sticky">
                        
                        {this.props.content && this.props.content.content &&
                        <React.Fragment>
                            <div
                                className="content__header content__header--rotated" 
                            >
                                <span className="rotated-text">
                                    <span 
                                        className="rotated-text__inner"
                                        dangerouslySetInnerHTML={{ __html: this.props.content.title.rendered}}
                                    />
                                </span>
                            </div>
                            </React.Fragment>
                        }
                        {/* <div className = "content-column--main__specs">
                            <ul>
                                <li className = "content-column--main__spec">
                                    <span>{this.props.acf && this.props.acf.date}</span>
                                </li>

                                <li className = "content-column--main__spec">
                                    <img src={this.urlAPI + "/public/images/icons/ic-sleeping-bag.png"} />
                                    <span>People supported</span>
                                    <span>{this.props.acf && this.props.acf.people_supported}</span>
                                </li>

                                <li className = "content-column--main__spec">
                                    <img src={this.urlAPI + "/public/images/icons/ic-upcycle.png"} />
                                    <span>Waste upcycled</span>
                                    <span>{this.props.acf && this.props.acf.waste_upcycled}</span>
                                </li>

                                <li className = "content-column--main__spec">
                                    <img src={this.urlAPI + "/public/images/icons/ic-sewing.png"} />
                                    <span>Work provided</span>
                                    <span>{this.props.acf && this.props.acf.work_provided}</span>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                );
            }
        }
    )
)

export default sizeMe({ monitorHeight: true })(ColumnMainCase);
