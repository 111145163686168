import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {Helmet} from "react-helmet";

import Navigation from '../components/header/navigation';
import NavigationTrigger from '../components/header/navigation-trigger';
import Branding from '../components/header/branding';
import NavigationFooter from '../components/header/navigation-footer';

import Addresses from '../components/general/addresses';
import AddressesHome from '../components/general/addresses-home';

const Template = inject("stores") (
    observer (
        class Template extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;

                this.state = {
                    title: "ShelterLab"
                };
            }

            componentDidMount = async() => {
                window.addEventListener('scroll', this.isSticky);
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.title !== this.props.title) {
                    let title = this.props.title;
                    this.setState({
                        title: title
                    })
                }
            }

            isSticky = () => {
                const navEl = document.querySelector('.wrapper--header-bar');
                const scrollTop = window.scrollY;

                const posTop = 0;

                if (scrollTop >= posTop) {
                    navEl.classList.add('--sticky');
                } else {
                    navEl.classList.remove('--sticky');
                }
            }
      
            render() {
                return (
                    <React.Fragment>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>{this.state.title}</title>
                            <link rel="canonical" href={"https:/www.shelterlab.world/"+ this.props.canonical} />
                        </Helmet>

                        <div className="wrapper--page">
                            <div className="wrapper--header">
                                <div className="wrapper--header__top">
                                    <div className="wrapper--header-bar">
                                        <Navigation />
                                        <div className="wrapper--header__topbranding">
                                            <Branding />
                                            <NavigationTrigger 
                                                trigger = {"header"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="wrapper--content">
                                {this.props.children}
                            </div>
                        </div>

                        {this.props.page !== 'start' &&
                            <div>
                                <Addresses
                                    
                                />
                            </div>
                        }

                        {this.props.page === 'start' &&
                            <div>
                                <AddressesHome
                                    
                                />
                            </div>
                        }

                        <div className="wrapper--footer">
                            <NavigationFooter 
                            
                            />                        
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default Template;
