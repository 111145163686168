import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

const BlockShelterLab = inject("stores") (
    observer (
        class BlockShelterLab extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    items: []
                };
            }

            componentDidMount = async() => {
                let idx;
                // idx = 63; //NL DEV
                // idx = 172; //EN DEV
                // idx = 33 //NL
                idx = 73; //EN
                const block1 = await this.storeContent.getContentBlock('pageblocks', idx);

                idx = 74; //EN
                const block2 = await this.storeContent.getContentBlock('pageblocks', idx);

                this.setState({
                    block1: block1,
                    block2: block2
                })
            }

            componentDidUpdate = async(prevProps) => {
                let items;
                if(prevProps.items !== this.props.items){
                    items = this.props.items;
                    this.setState({
                        items: items
                    })
                }

            }

            handleNav = (item) => {
                const idx = item.id;
                this.storeNavigation.setCurrentPage(idx);
                this.storeContent.setCurrentPageType('workshops');
                const url = '/content/' + item.title.rendered.toLowerCase();
                this.props.navigate(url);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="section section--shelterlab">
                            <div className="wrapper--blocks">
                                <div className="content-columns">
                                    <div className="content-column">
                                        <div className="content-column__content">
                                            {this.state.block1 && this.state.block1.content &&
                                                <h2 
                                                    className="content__header" 
                                                    dangerouslySetInnerHTML={{ __html: this.state.block1.title.rendered}}
                                                />
                                            }
                                            {this.state.block1 && this.state.block1.content &&
                                                <div 
                                                    className="content__text" 
                                                    dangerouslySetInnerHTML={{ __html: this.state.block1.content.rendered}}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="content-column">
                                        <div className="content-column__content">
                                            {this.state.block2 && this.state.block2.content &&
                                                <h2 
                                                    className="content__header" 
                                                    dangerouslySetInnerHTML={{ __html: this.state.block2.title.rendered}}
                                                />
                                            }
                                            {this.state.block2 && this.state.block2.content &&
                                                <div 
                                                    className="content__text" 
                                                    dangerouslySetInnerHTML={{ __html: this.state.block2.content.rendered}}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockShelterLab);