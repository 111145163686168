import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import i18n from "i18next";

const UnderConstruction = inject("stores") (
    observer (
        class UnderConstruction extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;   
            }

            componentDidMount() {
                this.currentLang = this.props.stores.storeUi.app_interface.lang;
            }

            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);
                
                return (
                    <div className="wrapper-content wrapper-content--under-construction">
                        <div className="under-construction__header">
                            <h1>SHELTERLAB</h1>
                            <h2>UNDER CONSTRUCTION</h2>
                        </div>
                    </div>
                );
            }
        }
    )
);

export default UnderConstruction;