import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

const BlockJob = inject("stores") (
    observer (
        class BlockJob extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    active: false
                };
            }

            componentDidMount = async() => {
                // const idx = this.storeNavigation.idCurrentPage; 
                // this.storeContent.setCurrentPageType('jobs');  
                // const content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, idx);

                // console.log('SbC job:', content)
            }

            componentDidUpdate = async(prevProps) => {

            }

            handleNav = (item) => {
                console.log('SbC job', item)
                const idx = item.id;
                this.storeNavigation.setCurrentPage(idx);
                this.storeContent.setCurrentPageType('jobs');
                const url = '/career/' + item.title.rendered.toLowerCase();
                this.props.navigate(url);
            }

            handleNavRoute = (page) => {
                const idx = 31;
                //nl 33
                this.storeNavigation.setCurrentPage(idx);
                //const url = '/content/' + page.toLowerCase();
                const url = '/' + page.toLowerCase();
                this.props.navigate(url);
            }

            triggerExcerpt = () => {
                this.setState({
                    active: !this.state.active
                })
            }
      
            render() {
                return (
                    <React.Fragment>
                        <li>
                            <div className="content-column__listitem-content">
                                <h2 
                                    className = "content__header --link" 
                                    dangerouslySetInnerHTML = {{ __html: this.props.item.title.rendered}}
                                    onClick = {() => this.handleNav(this.props.item)}
                                />
                                <span
                                    className = "content__excerpt-trigger --link"
                                    onClick = {() => this.triggerExcerpt(this.props.item)}
                                >
                                    +
                                </span>
                                {this.props.item.excerpt.rendered &&
                                    <div
                                        className = {"content__excerpt" + (this.state.active ? " --active" : "")}
                                        dangerouslySetInnerHTML = {{ __html: this.props.item.excerpt.rendered}}
                                    />
                                }
                                <span
                                    className = {"ankeiler__link --link content__excerpt" + (this.state.active ? " --active" : "")}
                                    onClick = {() => this.handleNav(this.props.item)}
                                >
                                    Read more
                                </span>
                                
                            </div>
                        </li>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockJob);