import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const NavigationExternalSites = inject("stores") (
    observer (
        class NavigationExternalSites extends Component {

            constructor(props) {
                super(props);
                this.storeUi = this.props.stores.storeUi;
                this.storeNavigation = this.props.stores.storeNavigation;
            }

            componentDidMount = async () => {
            }

            componentDidUpdate = async (prevProps) => {
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            handleNav = (url) => {
                window.open(url);
            }

            render() {
                return (
                    <div className="wrapper--navigation-external">
                        <ul className="navigation-external__list">
                            {this.storeNavigation.externalSites.map((site) => (
                                <li
                                    className = "--link"
                                    onClick = {() => this.handleNav(site.url)}
                                >
                                    <span>{site.displayValue}</span>
                                    <span>{site.displayUrl}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(NavigationExternalSites);

