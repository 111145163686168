import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

import * as ui from '../../utilities/ui';

const BlockNews = inject("stores") (
    observer (
        class BlockNews extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    items: []
                };
            }

            componentDidMount = async() => {
                const items = await this.storeContent.getContentPages('newsarticles', 100);
                //const items = await this.storeContent.getContentPages('posts', 100);
                console.log('SbC coll', items)

                this.setState({
                    items: items
                })
            }

            componentDidUpdate = async(prevProps) => {
            }

            handleNav = (item) => {
                const idx = item.id;
                this.storeNavigation.setCurrentPage(idx);
                // this.storeContent.setCurrentPageType('posts');
                // const url = '/content/' + item.title.rendered.toLowerCase();
                this.storeContent.setCurrentPageType('newsarticles');
                const url = '/articles/' + item.title.rendered.toLowerCase();
                this.props.navigate(url);

                ui.smoothScroll(0);
            }

            handleNavRoute = (page) => {
                const idx = 31;
                //nl 33
                this.storeNavigation.setCurrentPage(idx);
                //const url = '/content/' + page.toLowerCase();
                const url = '/' + page.toLowerCase();
                this.props.navigate(url);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="section section--news">
                            <div className={"content-columns " + this.props.grid + " " + (this.props.linkAll && " --haslinkall")}>
                                <div className="content-column">
                                    <ul>
                                        {this.state.items.map((item, idx) => (
                                            item.language === this.storeUi.lang &&
                                                idx < this.props.view &&
                                                    <li 
                                                        className = {this.props.format + " --link"}
                                                        onClick = {() => this.handleNav(item)}
                                                    >
                                                        <div 
                                                                className="content-column__listitem-content"
                                                                style= {{backgroundImage:`url(${item.better_featured_image && item.better_featured_image.media_details.sizes.large.source_url})` }}
                                                            >
                                                        
                                                            {/* <div 
                                                                className = "ankeiler__link --link"
                                                                onClick = {() => this.handleNav(item)}
                                                            >
                                                                Read more
                                                            </div> */}
                                                        </div>
                                                        <h2 
                                                            className = "content__header --link" 
                                                            dangerouslySetInnerHTML = {{ __html: item.title.rendered}}
                                                            onClick = {() => this.handleNav(item)}
                                                        />
                                                    </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            {this.props.linkAll &&
                            <div 
                                className="content-column__actions"
                            >
                                <button 
                                    className = "btn btn--primary --active"
                                    onClick = {() => this.handleNavRoute('news')}
                                >
                                    View all news articles
                                </button>
                            </div>
                            }
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockNews);