import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const Slideshow = inject("stores") (
    observer (
        class Slideshow extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;

                this.urlAPP = process.env.REACT_APP_URL;
                this.urlAPI = process.env.REACT_APP_URL_API;
            }

            componentDidMount = () => {

            }

            componentDidUpdate(prevProps) {
                
            }

            render() {
                return (
                    <div>
                        REACT SLIDESHOW
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(Slideshow);
