import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../utilities/withRouter';

import * as ui from '../utilities/ui';
import Template from '../templates/template';

import BlockNewsNavigation from '../components/blocks/block-news-navigation';
import Slideshow from '../elements/slideshow';

const Article = inject("stores") (
    observer (
        class Article extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                // this.env = process.env.REACT_APP_ENV;
                // this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;   
                this.storeContent = this.props.stores.storeContent;  
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                   
                }  
            }

            componentDidMount = async() => {
                ui.handleScroll('top');
                let slug = '';
                let route = '';
                let currentPage;
                let acf;

                const idx = this.storeNavigation.idCurrentPage;   
                this.storeContent.setCurrentPageType('newsarticles');      
                const content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, idx);

                if (Array.isArray(content)) {
                    content.map((page) => {
                        if (this.props.params.title.toLowerCase() === page.title.rendered.toLowerCase()) {
                            currentPage = page;
                            acf = page.acf;
                        }
                    })
                } else {
                    currentPage = content;
                    acf = content.acf;
                } 

                this.setState({
                    idx: idx,
                    route: route,
                    content: currentPage,
                    slug: slug,
                    acf: acf
                })
            }

            componentDidUpdate = async() => {
                let currentPage;
                let acf;

                if (this.storeNavigation.idCurrentPage !== this.state.idx) {
                    const idx = this.storeNavigation.idCurrentPage;
                    this.storeContent.setCurrentPageType('newsarticles');  
                    const content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, idx);

                    if (Array.isArray(content)) {
                        content.map((page) => {
                            if (this.props.params.case.toLowerCase() === page.title.rendered.toLowerCase()) {
                                currentPage = page;
                                acf = page.acf;
                            }
                        }) 
                    } else {
                        currentPage = content;
                        acf = content.acf;
                    }  
                    
                    this.setState({
                        content: currentPage,
                        acf: acf
                    })
                }
            }

            gotoBlock = () => {
                //ui.smoothScroll(this.blockRef.offsetTop - 40);
            }

            render() {
                const title = (this.state.content && this.state.content.title) ? this.state.content.title.rendered : ""

                return (
                    <Template
                        title = {"News | " + title}
                        canonical = {"news"}
                    >
                        <div className="wrapper-content wrapper-content--news-article">
                            <div className="content-columns">
                                <div className="content-column content-column--main">
                                    {/* {this.state.content && this.state.content.content &&
                                        <h1 
                                            className="content__header" 
                                            dangerouslySetInnerHTML={{ __html: this.state.content.title.rendered}}
                                        />
                                    } */}
                                    <div
                                        className="content__header content__header--rotated" 
                                    >
                                        <span className="rotated-text">
                                            <span 
                                                className="rotated-text__inner"
                                                
                                            >
                                                news
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="content-column content-column--secundary">
                                    {this.state.acf &&
                                        <div 
                                            className="content__text" 
                                            dangerouslySetInnerHTML={{ __html: "<h1 class='content__header --divider'>" + this.state.acf.article_title + "</h1>"}}
                                        />
                                    }
                                    <div 
                                        className="content__text content__text--metas"
                                    >
                                        {this.state.acf && this.state.acf.place &&
                                            <div 
                                                className="content__text--meta" 
                                                dangerouslySetInnerHTML={{ __html: this.state.acf.place}}
                                            />
                                        }
                                        {this.state.acf && this.state.acf.author &&
                                            <div 
                                                className="content__text--meta" 
                                                dangerouslySetInnerHTML={{ __html: "by "+ this.state.acf.author}}
                                            />
                                        }
                                        {this.state.acf && this.state.acf.date &&
                                            <div 
                                                className="content__text--meta" 
                                                dangerouslySetInnerHTML={{ __html: this.state.acf.date}}
                                            />
                                        }
                                    </div>
                                    {this.state.content && this.state.content.content &&
                                        <div 
                                            className="wp-content__text" 
                                            dangerouslySetInnerHTML={{ __html: this.state.content.content.rendered}}
                                        />
                                    }
                                </div>
                            </div>

                            <BlockNewsNavigation
                                linkAll = {true}
                                grid = {'--full'}
                            />
                        </div>
                    </Template>
                );
            }
        }
    )
)

export default withRouter(Article);
