import { makeAutoObservable, makeObservable, observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as content from '../communicator/content';
import * as pages from '../communicator/pages';
import * as menus from '../communicator/menus';

class StoreNavigation {
    // languages = [
    //     {
    //         id: 1,
    //         sku: 'nl_NL',
    //         displayValue: 'NL',
    //         locale: 'nl'
    //     },{
    //         id: 2,
    //         sku: 'en_UK',
    //         displayValue: 'EN',
    //         locale: 'en'
    //     }
    // ]

    languages = [
        {
            id: 2,
            sku: 'en_UK',
            displayValue: 'EN',
            locale: 'en'
        }
    ]

    utilities = [
        {
            id: 1,
            sku: 'TERMS',
            displayValue: 'Terms of Service',
            i18n: 'terms'
        },{
            id: 2,
            sku: 'PRIVACY',
            displayValue: 'Privacy Statement',
            i18n: 'privacy'
        }
    ]

    menusAll = []
    menuMain = []
    menuUtilities = []

    menuColumns = [
        {
            title: "work",
            items: [14,125,63]
        },{
            title: "action",
            items: [13,60,61]
        },{
            title: "history",
            items: [193,194,59]
        }
    ]

    externalSites = [
        {
            id: 1,
            sku: 'BASTIMMER',
            displayValue: 'Bas Timmer',
            url: 'https://bastimmer.com',
            displayUrl: 'bastimmer.com'
        },{
            id: 2,
            sku: 'SHELTERSUIT',
            displayValue: 'Sheltersuit Foundation',
            url: 'https://sheltersuit.com',
            displayUrl: 'sheltersuit.com'
        }
    ]

    socialSites = [
        {
            id: 1,
            sku: 'LINKEDIN',
            displayValue: 'LinkedIn',
            url: 'https://www.linkedin.com/company/shelter-lab/',
            img: 'https://www.shelterlab.world/api/public/images/social/ic-linkedin-black-trans.svg',
            bem: '--linkedin'
        }
    ]


    idCurrentPage = '';
    menuTrigger = false;

    constructor() {
        makeObservable(this, {
            idCurrentPage: observable,
            menusAll: observable,
            menuMain: observable,
            menuUtilities: observable,
            menuTrigger: observable
        })
        this.getMenus();
    }

    async getMenus() {
        let content = {};
        try {
            const returnData = await menus.getMenus();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    //console.log('SbC menus', returnData)
                    this.menusAll = returnData;
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getMenu(id) {
        try {
            const returnData = await menus.getMenu(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    if (id === 'menumainen') {
                        this.menuMain = returnData.items;
                    }
                    if (id === 'menuutilities') {
                        this.menuUtilities = returnData.items;
                    }
                    //console.log('SbC menu', this.menuUtilities)
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error";
            })
        }
    }

    setCurrentPage = (id) =>{
        this.idCurrentPage = id;
    }

    setMenuTrigger = (trigger) => {
        if (trigger === "header") {
            this.menuTrigger = !this.menuTrigger;
        } else {
            this.menuTrigger = false;
        }
    }
}

export default StoreNavigation;