import * as apifetch from './fetch.js';

export async function getMenus() {
    const urlAPI = process.env.REACT_APP_URL_API_WPMENUS;
    const urlEndpoint = urlAPI + 'menus/';

    return apifetch.shelterFetch(urlEndpoint, 'GET');
}

export async function getMenu(id) {
    const urlAPI = process.env.REACT_APP_URL_API_WPMENUS;
    const urlEndpoint = urlAPI + 'menus/' + id;

    return apifetch.shelterFetch(urlEndpoint, 'GET');
}

