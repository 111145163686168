import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

import FormContact from '../forms/form-contact';

const BlockContactForm = inject("stores") (
    observer (
        class BlockContactForm extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    items: []
                };
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = async(prevProps) => {

            }

            handleNav = (item) => {

            }

            handleNavRoute = (page) => {

            }
      
            render() {
                return (
                    <React.Fragment>
                        <FormContact

                        />
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockContactForm);