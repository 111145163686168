import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import NavigationMain from './navigation-main';
import NavigationExternal from './navigation-externalsites';
import NavigationSocials from './navigation-socials';
import NavigationLanguages from './navigation-languages';
import NavigationUtilities from './navigation-utilities';

const NavigationFooter = inject("stores") (
    observer (
        class NavigationFooter extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    menu: []
                };

                this.state = {};
            }

            componentDidMount = async() => {
                // let menu;
                // if (this.storeUi.lang === 'nl') {
                //     menu = await this.storeNavigation.getMenu('menumainnl');
                // } else {
                //     menu = await this.storeNavigation.getMenu('menumainen');
                // }
                // console.log('SbC menu', menu)
                // this.setState({
                //     menu: menu
                // })
            }

            componentDidUpdate = async(prevProps) => {

            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="wrapper--navigation">
                            <div className="navigation__columns">
                                <div className="navigation__column navigation-main">
                                    <NavigationMain 
                                        lang = {this.storeUi.lang}
                                        trigger = {"footer"}
                                    />
                                </div>
                                {/* <div className="navigation__column navigation-external">
                                    <NavigationExternal 

                                    />
                                </div> */}
                            </div>
                            <div className="navigation__columns">
                                <div className="navigation__column navigation-socialss">
                                    <NavigationSocials 

/                                   >
                                </div>
                            </div>
                            <div className="navigation__columns">
                                <div className="navigation__column navigation-languages">
                                    <NavigationLanguages 

                                    />
                                </div>
                                {/* <div className="navigation__column navigation-utilities">
                                    <NavigationUtilities 
                                        lang = {this.storeUi.lang}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default NavigationFooter;