import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as forms from '../communicator/forms';

class StoreForms {

    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeGeneral = this.storeRoot.storeGeneral;
    }

    initForm(formControls) {
        const updatedControls = {
            ...formControls
        };

        Object.keys(formControls).map((key, i) => {
            const updatedFormElement = {
                ...updatedControls[key]
            };

            if ('isRequired' in updatedFormElement.validationRules && updatedFormElement.value === '') {
                updatedFormElement.valid = false;
            } else {
                updatedFormElement.valid = true;
            }

            updatedControls[key] = updatedFormElement;
        });
        
        return updatedControls;
    }

    validateForm(formControls) {
        let formIsValid = true;

        Object.keys(formControls).map((key, i) => {
            if (Object.keys(formControls[key].validationRules).length !== 0 && formControls[key].valid === false) {
                formIsValid = false;
            }
        })

        return formIsValid;
    }

    validateRow(formControls, name, value, valid, touched) {        
        const updatedControls = {
            ...formControls
        };
        const updatedFormElement = {
            ...updatedControls[name]
        };
      
        updatedFormElement.value = value;
        updatedFormElement.touched = touched;
        if ('isRequired' in updatedFormElement.validationRules) {
            updatedFormElement.valid = valid;
        } else {
            updatedFormElement.valid = true;
        }
        updatedControls[name] = updatedFormElement;
        
        return updatedControls;
    }

    async sendFormContact(form_values) {
        const form_data = {
            from_firstname: form_values['firstname'].value,
            from_lastname: form_values['lastname'].value,
            from_email: form_values['email'].value,
            content: form_values['question'].value
        }
        try {
            const returnData = await forms.sendFormContact('contact', form_data);
            runInAction(() => {
                this.form_feedback = returnData.msg;
                this.openDialogFeedbackForm('vraag/opmerking');
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
    }

    async sendFormNewsletter(form_values) {
        const form_data = {
            from_firstname: form_values['firstname'].value,
            from_lastname: form_values['lastname'].value,
            from_company: form_values['companyname'].value,
            from_email: form_values['email'].value
        }
        try {
            const returnData = await forms.sendFormNewsletter('newsletter', form_data);
            runInAction(() => {
                this.form_feedback = returnData.msg;
                this.openDialogFeedbackForm('newsletter');
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
    }
}

export default StoreForms;