import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
//import classNames from 'classnames';

const LanguageSelector = inject("stores") (
    observer (
        class LanguageSelector extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                this.storeNavigation = this.props.stores.storeNavigation;
            }

            componentDidMount() {
                let lng = this.storeUi.lang;
                if (lng === '' && localStorage['i18nextLng']) {
                    lng = localStorage.getItem('i18nextLng');
                    this.storeUi.setLocale(lng); 
                }
            }

            getActiveLang(lang) {
                const lng = this.storeUi.lang;
                let classNames = "lang-selector__button";
                if (lang === lng) { 
                    classNames = classNames + ' --active';
                } else {
                    classNames = classNames + ' --link';
                }
                return classNames;
            }
     
            setLocale(lang) {
                this.storeUi.setLocale(lang);
            }
        
            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);

                const lang = this.storeUi.app_interface.lang;
        
                return (
                    <div className="lang-selector">
                        <ul className="navigation-languages__list">
                            {this.storeNavigation.languages.map((language) => (
                                <li
                                    className = {this.getActiveLang(language.locale)}
                                    onClick = {() => this.setLocale(language.locale)}
                                >
                                    {language.displayValue}
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
);

export default LanguageSelector;