import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const NavigationSocials = inject("stores") (
    observer (
        class NavigationSocials extends Component {

            constructor(props) {
                super(props);
                this.storeUi = this.props.stores.storeUi;
                this.storeNavigation = this.props.stores.storeNavigation;
            }

            componentDidMount = async () => {
            }

            componentDidUpdate = async (prevProps) => {
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            handleNav = (url) => {
                window.open(url);
            }

            render() {
                return (
                    <div className="wrapper--navigation-socials navigation-socials">
                        <ul className="navigation-socials__list">
                            {this.storeNavigation.socialSites.map((site) => (
                                <li
                                    className={"navigation-socials__listitem --link " + site.bem}
                                    onClick = {() => this.handleNav(site.url)}
                                >
                                    <img src={site.img} />
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(NavigationSocials);

