import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

import * as ui from '../../utilities/ui';

const BlockCases = inject("stores") (
    observer (
        class BlockCases extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    items: []
                };
            }

            componentDidMount = async() => {
                let idx;
                // idx = 63; //NL DEV
                // idx = 172; //EN DEV
                // idx = 33 //NL
                idx = 75; //EN
                const block1 = await this.storeContent.getContentBlock('pageblocks', idx);
                const items = await this.storeContent.getContentPages('casestudy', 100);
                let acf = block1.acf;

                this.setState({
                    items: items,
                    block1: block1,
                    acf: acf
                })
            }

            componentDidUpdate = async(prevProps) => {
                // let items;
                // if(prevProps.items !== this.props.items){
                //     items = this.props.items;
                //     this.setState({
                //         items: items
                //     })
                // }
            }

            handleNav = (item) => {
                const idx = item.id;
                this.storeNavigation.setCurrentPage(idx);
                this.storeContent.setCurrentPageType('casestudy');
                const url = '/case/' + item.title.rendered.toLowerCase();
                this.props.navigate(url);

                ui.smoothScroll(0);
            }

            handleNavRoute = (page) => {
                const idx = 17;
                this.storeNavigation.setCurrentPage(idx);
                //const url = '/content/' + page.toLowerCase();
                const url = '/' + page.toLowerCase();
                this.props.navigate(url);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="section section--cases">
                            {this.props.intro 
                            ?   <div className="content-columns">
                                    <div className="content-column content-column--main">
                                        {this.props.rotated &&
                                            <div className="content-columns">
                                                <div className="content-column">
                                                    <div
                                                        className="content__header content__header--rotated" 
                                                    >
                                                        <span className="rotated-text">
                                                            <span 
                                                                className="rotated-text__inner"
                                                                
                                                            >
                                                                Cases
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="content-column">
                                                    {this.state.block1 && this.state.block1.content &&
                                                        <div 
                                                            className="content__text" 
                                                            dangerouslySetInnerHTML={{ __html: this.state.block1.content.rendered}}
                                                        />
                                                    }
                                                    <div 
                                                        className="content-column__actions"
                                                    >
                                                        <button 
                                                            className = "btn btn--primary --active"
                                                            onClick = {() => this.handleNavRoute('case-studies')}
                                                        >
                                                            Explore
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {!this.props.rotated && this.state.block1 && this.state.block1.content &&
                                            <React.Fragment>
                                                <h2 
                                                    className="content__header" 
                                                    dangerouslySetInnerHTML={{ __html: this.state.block1.title.rendered}}
                                                />
                                                {this.state.block1 && this.state.block1.content &&
                                                    <div 
                                                        className="content__text" 
                                                        dangerouslySetInnerHTML={{ __html: this.state.block1.content.rendered}}
                                                    />
                                                }
                                                <div 
                                                    className="content-column__actions"
                                                >
                                                    <button 
                                                        className = "btn btn--primary --active"
                                                        onClick = {() => this.handleNavRoute('case-studies')}
                                                    >
                                                        {this.state.acf && this.state.acf.button_text}
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                    <div className="content-column content-column--blocks">
                                        <ul>
                                            {this.state.items.map((item) => (
                                                item.language === this.storeUi.lang &&
                                                item.acf.appear_in_homepage_block === '1' &&
                                                    <li 
                                                        className={(this.props.rotated ? "--low" : "") + " --link"}
                                                        onClick = {() => this.handleNav(item)}
                                                    >
                                                        {item.better_featured_image && item.better_featured_image.media_details.sizes.large
                                                        ?   <div 
                                                                className="content-column__listitem-content"
                                                                style= {{backgroundImage:`url(${item.better_featured_image.media_details.sizes.large.source_url})` }}
                                                            />
                                                        :   <div 
                                                                className="content-column__listitem-content"
                                                                style= {{backgroundImage:`url(${item.better_featured_image && item.better_featured_image.media_details.sizes.medium && item.better_featured_image.media_details.sizes.medium.source_url})` }}
                                                            />
                                                        }
                                                        <h2 
                                                            className = "content__header" 
                                                            dangerouslySetInnerHTML = {{ __html: item.title.rendered}}
                                                        />
                                                    </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            :   <div className={"content-columns " + this.props.grid}>
                                    <div className="content-column content-column--blocks">
                                        <ul>
                                            {this.state.items.map((item) => (
                                                item.language === this.storeUi.lang &&
                                                <li 
                                                    className="--link"
                                                    onClick = {() => this.handleNav(item)}
                                                >
                                                    {item.better_featured_image && item.better_featured_image.media_details.sizes.large
                                                    ?   <div 
                                                            className="content-column__listitem-content"
                                                            style= {{backgroundImage:`url(${item.better_featured_image && item.better_featured_image.media_details.sizes.large.source_url})` }}
                                                        />
                                                    :   <div 
                                                            className="content-column__listitem-content"
                                                            style= {{backgroundImage:`url(${item.better_featured_image && item.better_featured_image.media_details.sizes.medium.source_url})` }}
                                                        />
                                                    }
                                                    <h2 
                                                        className = "content__header" 
                                                        dangerouslySetInnerHTML = {{ __html: item.title.rendered}}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            }
                            
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockCases);